import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const GoogleNewSignup = () => {
    const [usersession, setUsersession] = useState('');
    const [preferences, setPreferences] = useState({
        terms: false,
        serviceCommunication: false,
        marketing: false
    });
    const [gotPref, setGotPref] = useState(false);

    const navigate = useNavigate();
    const xlocation = useLocation();
    const queryParams = new URLSearchParams(xlocation.search);
    const deeplink = queryParams.get("back");
    const hideMainMenu = true;
    const [showDoneError, setShowDoneError] = useState(false);

    let nextpage = '/';
    if (deeplink !== null) {
        nextpage = "/" + deeplink;
    }

    const GetPref = async (session_token) => {
        const response = await fetch(`${process.env.API_URL}/UserPref/${session_token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.API_KEY,
            },
        });
        if (response.status === 200) {
            const data = await response.json();
            setPreferences({
                terms: data.userpref.terms,
                serviceCommunication: data.userpref.servicecomms,
                marketing: data.userpref.marketing
            });
        }
        setGotPref(true);
    };

    const SavePref = async (newPreferences) => {
        const session_token = Cookies.get('c8_session_token');
        const response = await fetch(`${process.env.API_URL}/UserPref/${session_token}`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.API_KEY,
            },
            body: JSON.stringify(newPreferences)
        });
        if (response.status === 200) {
            console.log('Preferences updated successfully');
        } else {
            console.log('Error updating preferences');
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const updatedPreferences = { ...preferences, [name]: checked };
        setPreferences(updatedPreferences);
        SavePref(updatedPreferences); 
        setShowDoneError(false);
    };

    const handleNextPage = () => {
        if (preferences.terms) {
            window.location.href = nextpage;
        } else {
            setShowDoneError(true);
        }
    };

    useEffect(() => {
        document.title = "ACCOUNT CREATED | Cricket8";
    }, []);

    useEffect(() => {
        const session_token = Cookies.get('c8_session_token');
        if (session_token) {
            GetPref(session_token);
        }
    }, []);

    useEffect(() => {
        const mainMenu = document.getElementById('mainMenu');
        const menuBkgrd = document.getElementById('menuBkgrd');
        if (hideMainMenu && mainMenu) {
            mainMenu.style.display = 'none'; 
            menuBkgrd.style.display = 'none'; 
        } else if (mainMenu) {
            mainMenu.style.display = ''; 
            menuBkgrd.style.display = ''; 
        }
    }, [hideMainMenu]);

    return (
        <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[20px]'>
            <div className='mt-[20px] flex'>
                <div className='bg-lime h-[28px] w-[28px] md:h-[50px] md:w-[50px] mt-[2px] md:mt-[3px] p-[3px] md:p-[10px] text-main'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                </div>
                <h1 className='ml-[20px] text-[30px] md:text-[55px] !text-lime'>ACCOUNT CREATED</h1>
            </div>
            <div className='mt-[10px] text-white font-chakra font-normal normal-case ml-[48px] md:ml-[70px]'>
                Welcome to Cricket8!
            </div>
            {gotPref ? (
            <div className='bg-[#482a6a] p-[20px] mt-[30px]'>
                <div className='text-white font-anton text-[16px]'>CONSENT PREFERENCES</div>
                
                <div className='flex mt-[30px]'>
                    <div className='border-4 border-bottom-2 border-white border-solid bg-white h-6'>
                        <input 
                            type='checkbox' 
                            className='peer relative appearance-none shrink-0 w-4 h-4 border-0 m-0 p-0 bg-white checked:bg-red cursor-pointer' 
                            name="terms"
                            checked={preferences.terms}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className='ml-[10px] pt-[4px] text-white font-chakra normal-case'>I agree to Cricket8's <a href='/terms' className='text-white underline' target='_blank'>terms and conditions</a>.</div>
                </div>
                
{/*                 <div className='flex mt-[20px]'>
                    <div className='border-4 border-bottom-2 border-white border-solid bg-white h-6'>
                        <input 
                            type='checkbox' 
                            className='peer relative appearance-none shrink-0 w-4 h-4 border-0 m-0 p-0 bg-white checked:bg-red cursor-pointer' 
                            name="serviceCommunication"
                            checked={preferences.serviceCommunication}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className='ml-[10px] pt-[4px] text-white font-chakra normal-case'>I'm happy to receive Cricket8 service communications.</div>
                </div>
 */}
                <div className='flex mt-[20px]'>
                    <div className='border-4 border-bottom-2 border-white border-solid bg-white h-6'>
                        <input 
                            type='checkbox' 
                            className='peer relative appearance-none shrink-0 w-4 h-4 border-0 m-0 p-0 bg-white checked:bg-red cursor-pointer' 
                            name="marketing"
                            checked={preferences.marketing}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className='ml-[10px] pt-[4px] text-white font-chakra normal-case'>I'm happy to receive Cricket8 marketing messages.</div>
                </div>

                <div className="mt-[30px] text-white font-chakra normal-case">
                    Cricket8 will never share or sell your data.
                </div>
                <div className="mt-[20px] text-white font-chakra normal-case">
                    These preferences can be changed in your Account area.
                </div>
                <div>
                    <div onClick={() => handleNextPage()} className={`
                        mt-[30px] w-full h-[50px] text-main pt-[15px] font-anton text-[18px] text-center 
                        ${!preferences.terms  ? 'cursor-not-allowed bg-grey' : 'cursor-pointer bg-lime' }
                        `}>
                        DONE
                    </div>
                    {showDoneError && (
                        <div className='text-red text-center mt-[10px]'>Agree to terms and conditions before proceeding</div>
                    )}
                </div>
            </div>
            ) : ( null )}
        </div>
    );
};

export default GoogleNewSignup;
